




























































































import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import {defineComponent, PropType} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

const parts: ('A' | 'B' | 'C')[] = ['A', 'B', 'C'];
export type UOttawaPostLab4ObservationsPart = (typeof parts)[number];

export default defineComponent({
  name: 'UOttawaPostLab4Observations',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: '',
        part2: '',
        part3a: '',
        part3b: '',
        part3c: '',
        part3d: '',
        part3e: '',
        part4: '',
        language: 'en',
      },
      partAttachments: {
        A: [],
        B: [],
        C: [],
      } as Record<UOttawaPostLab4ObservationsPart, File[]>,
      questionParts: parts,
      questions: [
        {
          en: '1) When you compared your endpoint results with the equivalence point results in the titration did you expect them to be the same? If so, were they? If not, can you explain why?',
          fr: '1) Quand vous avez comparé les résultats pour le point d’équivalence et le point de virage après le titrage, avez-vous pensé qu’ils auraient être pareils ? Si oui, étaient-ils ? Sinon, pouvez-vous expliquer pourquoi ?',
          inputName: 'part1',
        },
        {
          en: '2) How reproducible were your results? Did you expect the three trials to be the same? Can you elaborate on the factors why there were or were not any variations?',
          fr: '2) Est-ce que les résultats ont été reproductibles ? Est-ce que les résultats pour les trois essais doivent être pareils ou similaires ? Élaborez les raisons pour des variations ou un manque des variations.',
          inputName: 'part2',
        },
        {
          en: 'a) When no base was added',
          fr: 'a) Après l’ajoute de 0 mL de la base',
          inputName: 'part3a',
        },
        {
          en: 'b) Midway to the first equivalence point',
          fr: 'b) Au premier point de demi-équivalence (demi-neutralisation)',
          inputName: 'part3b',
        },
        {
          en: 'c) At the first equivalence point',
          fr: 'c) Au premier point d’équivalence',
          inputName: 'part3c',
        },
        {
          en: 'd) Midway to the second equivalence point',
          fr: 'd) Au deuxième point de demi-équivalence (demi-neutralisation)',
          inputName: 'part3d',
        },
        {
          en: 'e) At the second equivalence point',
          fr: 'e) Au deuxième point d’équivalence',
          inputName: 'part3e',
        },
        {
          en: '4) Discuss why this is an analytical technique.',
          fr: '4) Expliquez pourquoi le titrage est considéré une technique analytique.',
          inputName: 'part4',
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.partAttachments.A, ...this.partAttachments.B, ...this.partAttachments.C];
    },
    attachmentParts(): Record<string, string> {
      const partMap: Record<string, string> = {};

      for (const part of this.questionParts) {
        for (const attachment of this.partAttachments[part]) {
          partMap[attachment.name] = part;
        }
      }

      return partMap;
    },
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
